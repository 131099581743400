// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'
import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import config from "./config/AppConfig"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n/i18n"
import "moment-timezone/node_modules/moment/locale/sv"
import TagManager from "react-gtm-module"

const tagManagerArgs = {
  gtmId: "GTM-56L35D3",
}

TagManager.initialize(tagManagerArgs)

const script = document.createElement("script")

script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&v=3.exp&libraries=geometry,drawing,places`
script.async = true
script.defer = true
script.type = "text/javascript"

document.body.appendChild(script)

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
  <React.Fragment>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.Fragment>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
