import { create, persist } from "mobx-persist"
import authStore from "./AuthStore"
import routerStore from "./RouterStore"
import projectStore from "./ProjectStore"
import recordStore from "./RecordStore"
import userStore from "./UserStore"
import organizationStore from "./OrganizationStore"
import localForage from "localforage"
// import projectStore from './ProjectStore';
import shareStore from "./ShareStore"
import commentStore from "./CommentStore"

const stores = {
  authStore,
  routerStore,
  organizationStore,
  projectStore,
  recordStore,
  userStore,
  shareStore,
  commentStore,
}

const hydrate = create({
  storage: localForage,
  jsonify: false,
})

hydrate("authStore", authStore).then(() => authStore.checkLogged())

export default stores
