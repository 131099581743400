import { observable, action } from "mobx"
import api from "../services/ApiDefault"
import { showMessage, showResponseError } from "../utilities/utils"
import _ from "lodash"
import i18n from "../i18n/i18n"
import { v4 } from "uuid"

export class CommentStore {
  // @desc this field is not persisted
  @observable isLoading = false
  @observable recordId = ""
  @observable comments = []
  @observable commentListEnd = false

  @action
  async getComments({ recordId, offset, seriesId }) {
    this.isLoading = true
    const params = { offset, seriesId }
    const response = await api.getComments(recordId, params)
    this.isLoading = false
    this.recordId = recordId
    if (response.ok) {
      this.comments = response.data
      if (response.data.length < 100) {
        this.commentListEnd = true
      } else {
        this.commentListEnd = false
      }
    } else {
      showResponseError(response)
    }
  }

  @action
  async addComment(recordId, data, commentImage) {
    this.isLoading = true
    let uploadId = null
    if (commentImage) {
      const headers = { "Content-Type": "multipart/form-data" }
      const formData = new FormData()
      const file = commentImage.file
      uploadId = v4()
      formData.append("uuid", uploadId)
      formData.append("imgFile", file)
      const response = await api.uploadCommentFile(recordId, formData, {
        headers,
        timeout: 10 * 60 * 1000,
      })
      if (!response.ok) {
        showResponseError(response)
        this.isLoading = false
        return false
      }
    }
    if (uploadId) data.uploadedUuids = [uploadId]
    const response = await api.addComment(recordId, data)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t("posted"))
      return true
      // routerStore.push(`/dashboard/comments/${response.data.newComment.id}`)
    } else {
      showResponseError(response)
      return false
    }
  }

  @action
  async updateComment(recordId, id, comment) {
    this.isLoading = true
    const response = await api.editComment(recordId, id, { comment })
    if (response.ok) {
      await this.getComments({ recordId })
      // routerStore.push(`/dashboard/comments/${comment.id}`)
      // showMessage(i18n.t('commentUpdated'))
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action
  async deleteComment(recordId, id) {
    this.isLoading = true

    const response = await api.deleteComment(recordId, id)
    this.isLoading = false
    if (response.ok) {
      await this.getComments({ recordId })
      // this.deleteId = id
      // showMessage(i18n.t('commentErased'))
      // routerStore.push('/dashboard')
    } else {
      showResponseError(response)
    }
  }
}

const commentStore = new CommentStore()
export default commentStore
