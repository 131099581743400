import React, { Component } from "react"
import "./style.css"

class Loading extends Component {
  render() {
    return (
      <div className="loading-wrap">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}

export default Loading
