import React, { Component } from "react"
import { Router, Route, Switch } from "react-router-dom"
// import { renderRoutes } from 'react-router-config';
import { Provider } from "mobx-react"
import stores from "./stores"
import Loadable from "react-loadable"
import { ToastContainer } from "react-toastify"
import "./App.scss"
import "./config"
import loading from "./components/Loading"
import TopLoadingBar from "react-top-loading-bar"
import Loading from "./utilities/loading"
import { syncHistoryWithStore } from "mobx-react-router"
import { createBrowserHistory } from "history"
import LoadingBar from "./components/LoadingBar/LoadingBar"
import { withTranslation } from "react-i18next"
import Modal from "react-modal"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import ChangeOwner from "./pages/ChangeOwner/ChangeOwner"
import ChangeOwnerRevoke from "./pages/ChangeOwnerRevoke/ChangeOwnerRevoke"

const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, stores.routerStore)

// Containers
const FrontLayout = Loadable({
  loader: () => import("./containers/FrontLayout"),
  loading,
})

const SetPassword = Loadable({
  loader: () => import("./pages/SetPassword/SetPassword"),
  loading,
})

const DashboardLayout = Loadable({
  loader: () => import("./containers/DashboardLayout"),
  loading,
})

const AdminLayout = Loadable({
  loader: () => import("./containers/AdminLayout"),
  loading,
})

const OrganizationLayout = Loadable({
  loader: () => import("./containers/OrganizationLayout"),
  loading,
})

const ShareLayout = Loadable({
  loader: () => import("./containers/ShareLayout"),
  loading,
})

// Pages
const Login = Loadable({
  loader: () => import("./pages/Login/Login"),
  loading,
})

const Logout = Loadable({
  loader: () => import("./pages/Logout/Logout"),
  loading,
})

const Register = Loadable({
  loader: () => import("./pages/Register/Register"),
  loading,
})

const ValidateRegister = Loadable({
  loader: () => import("./pages/ValidateRegister/ValidateRegister"),
  loading,
})

const Page404 = Loadable({
  loader: () => import("./pages/Page404/Page404"),
  loading,
})

const Page500 = Loadable({
  loader: () => import("./pages/Page500/Page500"),
  loading,
})

const ForgotPassword = Loadable({
  loader: () => import("./pages/ForgotPassword/ForgotPassword"),
  loading,
})

const ShareVideo = Loadable({
  loader: () => import("./pages/ShareVideo/ShareVideo"),
  loading,
})

const JoinProject = Loadable({
  loader: () => import("./pages/JoinProject/JoinProject"),
  loading,
})

@withTranslation()
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: true,
    }
  }

  componentDidMount() {
    Modal.setAppElement("#root")
  }

  render() {
    return (
      <Provider {...stores}>
        <DndProvider backend={HTML5Backend}>
          <Router history={history}>
            <LoadingBar ref={(ref) => Loading.setComponent(ref)} />
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/logout" name="Loout Page" component={Logout} />
              <Route exact path="/register" name="Register Page" component={Register} />
              <Route
                exact
                path="/register-link/:resetKey"
                name="Join Page"
                component={JoinProject}
              />
              <Route
                exact
                path="/validate/:validateKey"
                name="Validate Register Page"
                component={ValidateRegister}
              />
              <Route
                exact
                path="/forgotpassword"
                name="Forgot Password"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/set-password/:resetKey"
                name="Set Password"
                component={SetPassword}
              />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/" name="Home" component={FrontLayout} />
              <Route path="/dashboard" name="Admin" component={DashboardLayout} />
              <Route path="/admin" name="Admin" component={AdminLayout} />
              <Route path="/organizations" name="Organizations" component={OrganizationLayout} />
              <Route
                path="/change-owner/:orgId/:token"
                name="change-owner"
                component={ChangeOwner}
              />
              <Route
                path="/change-owner-revoke/:orgId/:token"
                name="change-owner-revoke"
                component={ChangeOwnerRevoke}
              />
              <Route path="/share/:id" name="Share" exact component={ShareLayout} />
              <Route
                path="/share/:id/folder/:folderId"
                name="Share"
                exact
                component={ShareLayout}
              />
              <Route
                path="/share/:id/record/:recordId"
                name="Share"
                exact
                component={ShareLayout}
              />
              <Route
                path="/share/:id/folder/:folderId/record/:recordId"
                name="Share"
                exact
                component={ShareLayout}
              />
              <Route
                path="/share-record/:recordId/:token"
                name="ShareVideo"
                exact
                component={ShareVideo}
              />
              <Route
                path="/share-record/:recordId/:token/:index"
                name="ShareVideoSeries"
                exact
                component={ShareVideo}
              />
            </Switch>
            <ToastContainer />
          </Router>
        </DndProvider>
      </Provider>
    )
  }
}

export default App
