import Api from "../services/Api"
import FixtureApi from "../services/FixtureApi"
import Config from "../config/DebugConfig"

let api = Api.create()
if (Config.useFixtures) {
  api = FixtureApi
}

export default api
