import moment from "moment"
import { toast } from "react-toastify"
import Config from "../config/AppConfig"

export const removeNonNumber = (string = "") => string.replace(/[^\d]/g, "")
export const removeLeadingSpaces = (string = "") => string.replace(/^\s+/g, "")

export const url = (uri) => {
  if (uri && uri.startsWith("http")) return uri
  return Config.apiURL + uri
}

export function getMessageError(response) {
  if (response) {
    if (response.data && response.data.message) {
      return response.data.message
    } else {
      return response.problem
    }
  } else {
    return "unknownError"
  }
}

export function getResponseError(response) {
  if (response) {
    if (response.data && response.data.message) {
      return response.data.message.replace(/^[A-Z_]*:/, "")
    } else {
      return response.problem
    }
  } else {
    return "Unknown error!"
  }
}

export function getDataResponse(response) {
  if (response && response.data) {
    return response.data.data
  } else {
    return null
  }
}

export function getAge(dateString) {
  var birthDay = moment(dateString).format("YYYY/MM/DD")
  var today = new Date()
  var birthDate = new Date(birthDay)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export function yearsRevenue(startYear) {
  var currentYear = new Date().getFullYear()
  var years = []
  startYear = startYear || 2018

  while (startYear <= currentYear) {
    years.push(startYear++)
  }

  return years
}

export function showResponseError(response) {
  toast.error(getResponseError(response), {
    autoClose: 3000,
  })
}

export function showError(errorMessage, details) {
  toast.error(errorMessage, {
    autoClose: 3000,
  })
}

export function showMessage(message) {
  toast.success(message, {
    autoClose: 3000,
  })
}
